/*
    * ======================== *
    * Define Vars *
    * ======================== *
*/
// const...
/*
    * end/ Define Vars *
*/




/*
    * ========================= *
    * Import internal libraries *
    * ========================= *
*/
require('./is-img-webp.js');
/*
    * end/ Import internal libraries  *
*/




/*
    * ======================== *
    * Imports Yarn packages using CommonJS modules import method  *
    * ======================== *
*/
const $ = require('jquery');
window.$ = window.jQuery = jQuery;

// jQuery plugins
const polyfillObserver = require('intersection-observer');
const backstretch = require('jquery-backstretch');
const hammerjs = require('hammerjs');
const cssua = require("cssuseragent");
const lozad = require('lozad');
import tippy from 'tippy.js';
/*
    * end/ Imports Yarn packages *
*/




/*
    * ======================== *
    * Launch native javascript plugin *
    * ======================== *
*/
// Fastclick
const FastClick = require('fastclick');
FastClick.attach(document.body);

// Wow.js
const WOW = require('wowjs');
window.wow = new WOW.WOW({
    live: false
});
window.wow.init();

// LazyLoad on images with Lozad.js
const imgObserver = lozad('[data-lazy]', {
    rootMargin: '120px 0px'
});
imgObserver.observe();

// Sticky on categories page
const Sticky = require('sticky-js');
const sticky = new Sticky('.sticky');
/*
    * end/ Launch native javascript plugin *
*/




/*
    * ======================== *
    * jQuery Plugins
    * ======================== *
*/
$(function () {
    /*
    -- @ LINKS @ --
    */
    // External links
    $(document).on('click', 'a[href$=".pdf"], a.external-link', function (e) {
        e.preventDefault()
        window.open($(this).attr("href"))
    })
    // Disabled links
    $(document).on('click', 'a.noLink, a[href="GOTOLINK"]', function (e) {
        e.preventDefault()
    })
    // Backtotop links
    $(document).on('click', 'a.backtotop', function (e) {
        e.preventDefault()
        $('body,html').animate({scrollTop: 0}, 250, 'swing');
    })


    /*
        -- @ BACKSTRETCH @ --
        * Attach responsive background-images to elements
    */
    // Launch Backstretch with lazy load
    const backstretchObserver = lozad('.backstretch', {
        rootMargin: '50px 0px',
        loaded: function(el) {
            let imgName = $(el).attr('data-img');

            if (typeof imgName !== 'undefined' && imgName.length) {

                // If brower supports WebP img, so change image extension 8) \m/
                if(Modernizr.webp) {
                    imgName = imgName.substr(0, imgName.lastIndexOf(".")) + ".webp";
                }

                $(el).backstretch(imgName);
            }
        }
    });
    backstretchObserver.observe();


    /*
        -- @ MAIN MENU PHONE @ --
        * Show the hamburger button for main menu on phones
        */
    let _closeMenus = function () {
        $('html, body').removeClass('opened-menu')
        // Remove "shown" class to all menus
        $('#main-header nav').removeClass('shown')
        // Remove "is-active" class to all hamburger buttons
        $('.js-open-menu button').removeClass('is-active')
    }
    let _openMenu = function (menu, button) {
        $('html, body').addClass('opened-menu')
        menu.addClass('shown')
        button.addClass('is-active')
    }
    // Click on Hamburger
    $(document).on('click', '.js-open-menu button', function (e) {
        e.preventDefault()

        let $menu = $('#js-main-nav')

        if (!$menu.is('nav')) {
            alert('<nav> element must be placed just after the hamburger (msg from app.js)')
            return;
        }

        if ($(this).hasClass('is-active')) {
            _closeMenus();
        } else {
            _openMenu($menu, $(this));
        }
    });

    // HammerJS
    if ($('#js-main-nav').length) {
        // Swipe left to close menus
        let navBottomHeader = document.getElementById('js-main-nav');
        let hammerBottomMenu = new Hammer(navBottomHeader);

        hammerBottomMenu.on('swipeleft', function (ev) {
            _closeMenus();
        });

        let logo = document.getElementById('logo');
        let hammerLogo = new Hammer(logo);

        // Swipe Right on logo = open main menu
        hammerLogo.on('swiperight', function (ev) {
            _openMenu($('#js-main-nav'), $('.js-open-menu button'));
        });
    }


    /*
     -- @ HEADER : bind scroll event @ --
    */
    function fireAction(pos) {
        if (pos < 178) {
            $('#js-main-nav').removeClass('fixed');
        } else {
            $('#js-main-nav').addClass('fixed');
        }
    }

    let
        last_known_position = 0,
        ticking = false;

    window.addEventListener('scroll', function (e) {
        last_known_position = window.scrollY;

        if (!ticking) {
            window.requestAnimationFrame(function () {
                fireAction(last_known_position);
                ticking = false;
            });
        }

        ticking = true;

        if($('.catalog').length)
            sticky.update();
    });
    // Launch fireAction when page is loaded
    fireAction(window.scrollY);



    /*
        -- Anchors on CATALOG page --
    */
    $(document).on('click', 'aside nav a', function(e){
        e.preventDefault();

        let _rel = $(this).attr('href')

        if($(_rel).length) {
            let _offsetTop = $(_rel).offset().top

            // Scroll to anchor
            $('html, body').animate({
                scrollTop: (_offsetTop - 69) + 'px'
            }, 'fast');
        }
    });
    // Scrool to category if a category is choosen
    if($('body[data-scrollto]').length) {

        let
            $el = $('body').data('scrollto'),
            $navLink = $('aside nav a[href="#'+$el+'"]');


        if($navLink.length) {
            $navLink.trigger('click')
        }
    }


    /*
        -- Anchors on CATALOG page --
    */
    $(document).on('click', '.wrap-questions h2 a', function(e){
        e.preventDefault();

        let
            $this = $(this),
            $answer = $this.parents('article')

        $answer.toggleClass('active')

    });


    /*
        -- TippyJS on form errors
    */
    tippy('.tippyjs', {
        placement: 'top-start',
        touchHold : true
    });


    /*
        -- Product detail : Thumbnails click
    */
    $(document).on('click', '.product-detail .product-thumbs a', function(e){
        e.preventDefault();

        let _src = $(this).attr('href')
        let _main = $('.wrap-product-infos-img .main-img')

        _main.attr('src', _src)
    })
});
/*
    * end/ jQuery Plugins
*/
